import React from "react"
import Image from "gatsby-image/withIEPolyfill"

import { AspectRatio, Box, ColorMode, ScaleWrapper, Wrapper } from "components"

const SingleImageSlice = ({ data }) => {
  return (
    <Box
      className="singleImage-slice"
      as="section"
      position="relative"
      py={["layout.2", "layout.4"]}
    >
      {/* ~~~~~~~~~~ BACKGROUNDS ~~~~~~~~~~ */}
      {(data.topBackground === "Gray" || data.topBackground === "Black") && (
        <ColorMode mode={data.topBackground === "Black" ? "dark" : "light"}>
          <Box
            position="absolute"
            top={0}
            left={[0, 32]}
            width={["100%", "calc(100% - 64px)"]}
            height="50%"
            bg={data.topBackground === "Gray" ? "wash" : "background"}
          />
        </ColorMode>
      )}
      {(data.bottomBackground === "Gray" ||
        data.bottomBackground === "Black") && (
        <ColorMode mode={data.bottomBackground === "Black" ? "dark" : "light"}>
          <Box
            position="absolute"
            bottom={0}
            left={[0, 32]}
            width={["100%", "calc(100% - 64px)"]}
            height="50%"
            bg={data.bottomBackground === "Gray" ? "wash" : "background"}
          />
        </ColorMode>
      )}

      {/* ~~~~~~~~~~ CONTENT ~~~~~~~~~~ */}
      <Wrapper position="relative" maxWidth={1080 + 64 * 2}>
        {/* Image */}
        <AspectRatio ratio="16:9" overflow="hidden">
          <ScaleWrapper>
            <Image
              fluid={data.image.fluid}
              alt={data.image.alt}
              style={{
                width: "100%",
                height: "100%",
              }}
              objectFit="cover"
              objectPosition="center"
            />
          </ScaleWrapper>
        </AspectRatio>
      </Wrapper>
    </Box>
  )
}

export default SingleImageSlice
