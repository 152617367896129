import React from "react"
import { Link } from "gatsby"

import { Box, Button, Flex, Wrapper } from "components"

const Closing = ({ data }) => (
  <Box
    width={["100%", "calc(100% - 64px)"]}
    mx="auto"
    py={["layout.2", "layout.4"]}
  >
    <Wrapper>
      <Flex as="section" id="closing-navigation" justifyContent="space-between">
        {data.prev && (
          <Button
            children="Previous"
            as={Link}
            to={"/news/" + data.prev.uid}
            backArrow
          />
        )}
        {data.next && (
          <Button children="Next" as={Link} to={"/news/" + data.next.uid} />
        )}
      </Flex>
    </Wrapper>
  </Box>
)

export default Closing
