import React from "react"
import { graphql } from "gatsby"

import { Box, Button, SEO, PageHeader, Text, Wrapper } from "components"

import QuoteSlice from "./_quoteSlice"
import RichTextSlice from "./_richTextSlice"
import SingleImageSlice from "./_singleImageSlice"
import Closing from "./_closing"

function NewsItem({ data }) {
  return (
    <>
      <SEO
        title={
          data.page.data.title ? data.page.data.title.text : "Teine Energy"
        }
        description={data.page.data.excerpt && data.page.data.excerpt}
        imageOpenGraph={
          data.page.data.hero_image && data.page.data.hero_image.url
        }
        imageTwitter={
          data.page.data.hero_image && data.page.data.hero_image.url
        }
        imageAlt={data.page.data.hero_image && data.page.data.hero_image.alt}
      />
      {/* News item heading, date and hero image */}
      <PageHeader
        data={{
          breadcrumb: { label: "News", to: "/news" },
          heading: data.page.data.title && data.page.data.title.html,
          children: (
            <Box mt={["layout.2", "layout.4"]} alignItems="center">
              <Text size={400}>Posted {data.page.data.date}</Text>
              {data.page.data.pdf && data.page.data.pdf.url && (
                <Box mt="layout.4">
                  <Button
                    children={`Download pdf`}
                    as="a"
                    href={data.page.data.pdf.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    downArrow
                  />
                </Box>
              )}
            </Box>
          ),
          image: data.page.data.hero_image,
          bottomBackground: "Gray",
        }}
      />
      <Box bg="wash" width={["100%", "calc(100% - 64px)"]} mx="auto">
        <Wrapper py={["layout.4", "layout.6"]}>
          {/* Slice section */}
          {data.page.data.body &&
            data.page.data.body.length > 0 &&
            data.page.data.body.map(({ slice_type, primary }, index) => {
              switch (slice_type) {
                case "text":
                  return (
                    <RichTextSlice
                      data={{
                        content: primary.text && primary.text.html,
                      }}
                      key={"slice" + slice_type + index}
                    />
                  )
                case "quote":
                  return (
                    <QuoteSlice
                      data={{
                        content: primary.quote && primary.quote.html,
                        author: primary.quote && primary.author,
                      }}
                      key={"slice" + slice_type + index}
                    />
                  )
                case "image":
                  return (
                    <SingleImageSlice
                      data={{
                        image: primary.image,
                        topBackground:
                          data.page.data.body[index - 1] &&
                          data.page.data.body[index - 1].primary &&
                          data.page.data.body[index - 1].primary
                            .background_color,
                        bottomBackground:
                          data.page.data.body[index + 1] &&
                          data.page.data.body[index + 1].primary &&
                          data.page.data.body[index + 1].primary
                            .background_color,
                      }}
                      key={"slice" + slice_type + index}
                    />
                  )
                default:
                  return null
              }
            })}
        </Wrapper>
      </Box>
      {/* PREVIOUS/NEXT BUTTONS */}
      {((data.next && data.next.data) || (data.prev && data.prev.data)) && (
        <Closing
          data={{
            next: data.next,
            prev: data.prev,
          }}
        />
      )}
    </>
  )
}

export const query = graphql`
  query NewsItemQuery($uid: String!, $next: String, $prev: String) {
    newsTitle: prismicNews {
      data {
        subtitle {
          text
        }
      }
    }
    page: prismicNewsItem(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
          html
        }
        date(formatString: "MMM DD, YYYY")
        excerpt
        hero_image {
          url
          alt
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
        }
        pdf {
          url
        }

        body {
          ... on PrismicNewsItemBodyText {
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicNewsItemBodyImage {
            slice_type
            primary {
              image {
                fluid(maxWidth: 192) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicNewsItemBodyQuote {
            slice_type
            primary {
              author
              quote {
                html
              }
            }
          }
        }
      }
    }
    next: prismicNewsItem(uid: { eq: $next }) {
      uid
      data {
        title {
          text
        }
      }
    }
    prev: prismicNewsItem(uid: { eq: $prev }) {
      uid
      data {
        title {
          text
        }
      }
    }
  }
`

export default NewsItem
