import React from "react"

import { Box, RichText } from "components"

// A header + paragraph section, to be used throughout the site to deliver
// factoid-style information. Styled as a small block of text, with an image above.

const RichTextSlice = ({ data }) => (
  <Box width="100%" maxWidth={1080 / 2} mx="auto" py={["layout.2", "layout.4"]}>
    {data.content && (
      <RichText size={500} content={{ html: data.content }} color="text" />
    )}
  </Box>
)

export default RichTextSlice
