import React from "react"

import { Box, RichText, Text } from "components"

// A header + paragraph section, to be used throughout the site to deliver
// factoid-style information. Styled as a small block of text, with an image above.

const QuoteSlice = ({ data }) => (
  <Box width="100%" maxWidth={1080 / 2} mx="auto" py={["layout.2", "layout.4"]}>
    {data.content && (
      <RichText
        size={600}
        content={{ html: data.content }}
        mb={data.author && ["layout.2", "layout.2"]}
        textAlign="center"
        color="text"
      />
    )}
    {data.author && (
      <Text children={data.author} size={500} textAlign="center" color="alt" />
    )}
  </Box>
)

export default QuoteSlice
